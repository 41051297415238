.toggle-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 30px;
  font-size: 0.9em;
}
.toggle-button .toggle-button-option {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: solid 1px #cacbcf;
  cursor: pointer;
  padding: 5px;
}
.toggle-button-option:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.toggle-button-option:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.toggle-button-option.active {
  background: #e5f7fd;
  border-color: '#00afed';
}

.toggle-button-option.disabled {
  color: #343434;
  background-color: #e5e6ec;
  cursor: default;
  pointer-events: none;
}

.toggle-button-option.disabled > .button-count {
  background-color: #cacbcf;
}
