.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-wrapper input {
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 12px;
  padding: 5px;
  width: 100%;
}

.react-datepicker__input-container .react-datepicker__close-icon::after {
  background-color: #999;
  border-radius: 30%;
  height: 11px;
  width: 11px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  line-height: 1.4rem;
  width: 1.5rem;
}
