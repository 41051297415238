.unit-wrapper {
  text-align: center;
  margin-top: 4px;
}

.unit-wrapper label {
  margin: 0 5px;
  font-family: inherit;
  color: inherit;
  border-bottom: none;
}

.input-range-wrapper {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 25px;
  position: relative;
  font-size: 0.8rem;
}

.input-range__label {
  display: none;
}

.input-range--disabled {
  background: #dfdfe1;
}

.input-range--disabled .input-range__slider,
.input-range--disabled .input-range__track {
  cursor: default;
}
