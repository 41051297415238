.booleanFacetWrapper {
  display: flex;
  flex-direction: row;
  height: 30px;
  font-size: 0.9em;
}
.booleanAggOption {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: solid 1px #cacbcf;
}
.booleanAggOption:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.booleanAggOption:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.booleanAggOption.active {
  background: #e5f7fd;
  border-color: '#00afed';
}

.booleanAggOption {
  cursor: pointer;
}
