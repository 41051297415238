.inputWrapper {
  border: solid 2px lightgrey;
  padding: 5px;
}

.inputWrapper.focused {
  box-shadow: 0px 0px 10px skyblue;
}

.inputWrapper input:focus {
  outline: none;
}

.inputWrapper .inputIcon {
  color: lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputWrapper .inputRightIcon {
  color: lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
}
